
import { compact, flatMap, sortBy } from "lodash";
import { TrendView } from "../types";
import { Innovation, Organisation, Technology } from "@shapeable/copper-connect-types";


type InnovationViewDefiniton = {
  hasLibrary: boolean;
  hasCommunity: boolean;
  hasHorizons: boolean;
  hasCatalysts: boolean;
  hasTechnologies: boolean;
  filteredTechnologies: Technology[];
  technologyOrganisations: Organisation[];
};

export const useInnovationViewDefinition: (innovation: Innovation) => InnovationViewDefiniton = 
  (entity: Innovation) => {
    const { description, example, catalysts = [], horizons = [], people = [], 
      organisations = [], citations = [] } = entity;

    const stakeholders = [...sortBy(organisations, 'name'), ...sortBy(people, 'name')];

    const filteredTechnologies = entity.technologies.filter(technology => technology?.organisations?.length > 0);

    const technologyOrganisations: Organisation[] = flatMap(entity.technologies, technology => technology?.organisations);

    const hasCommunity = !!stakeholders.length;
    const hasTechnologies = !!filteredTechnologies.length; 
    const hasLibrary = !!citations.length; // TODO hook this up to library checks
    const hasHorizons = !!horizons.length;
    const hasCatalysts = !!catalysts.length;

    return {
      hasLibrary,
      hasCommunity,
      hasTechnologies,
      hasHorizons,
      hasCatalysts,
      filteredTechnologies,
      technologyOrganisations,
    };
    
  };