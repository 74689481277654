import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { Innovation } from '@shapeable/copper-connect-types';
import { CitationCard, ContentNode, ContentTitle, EntityFilters, EntityGrid, useLang, useView } from '@shapeable/ui';
import { HorizonCardGrid } from './horizon-card-grid';
import { EntityEmbedReveal } from './entity-embed-reveal';
import { MarketMapGrid } from './market-map-grid';
import { InnovationView } from 'types';
import { useInnovationViewDefinition } from '../../hooks/use-innovation-view-definition';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { useMarketMapFilters } from '../../hooks/use-market-map-filters';
import { compact } from 'lodash';
import { horizonItro } from '../../data';
import { GptLayout } from '@shapeable/gpt';
const cls = classNames('innovation-view-layout');

// -------- Types -------->

// -------- Props -------->

export type InnovationViewLayoutProps = Classable & HasChildren & { 
  entity?: Innovation;
};

export const InnovationViewLayoutDefaultProps: Omit<InnovationViewLayoutProps, 'entity'> = {
};


// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(7)} 0;
    font-size: 1em;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: ${theme.COLOR('heading')};
    font-size: 1em;
    margin-bottom: 0.5em;
  `,
});

const TechnologyTitleStyles = breakpoints({
  base: css`
    border-bottom: 1px solid #BFC0BF};
    
  `
});

const ExampleStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});

const CatalystStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});




const HorizonTypeStyles = breakpoints({
  base: css`
    
  `,
});

const StakeholderCellStyles = breakpoints({
  base: css`
    
  `,
});

const MarketMapStyles = breakpoints({
  base: css`
    
  `,
});

const CitationsStyles = breakpoints({
  base: css`
    
  `,
});

const StakeholderMapContainerStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(5)};
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h1`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Example: styled(ContentNode)`${ExampleStyles}`,
      Horizontype: styled(HorizonCardGrid)`${HorizonTypeStyles}`,
      Catalyst: styled(EntityEmbedReveal)`${CatalystStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`,

      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

      Stakeholders: styled(EntityGrid)`${StakeholderCellStyles}`,

      StakeHolderMapContainer: styled.div`${StakeholderMapContainerStyles}`,
        TechnologyTitle: styled(ContentTitle)`${TechnologyTitleStyles}`,
        MarketMap: styled(MarketMapGrid)`${MarketMapStyles}`,

      Citations: styled(EntityGrid)`${CitationsStyles}`,
};

export const InnovationViewLayout: Shapeable.FC<InnovationViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, example, catalysts = [], horizons = [], people = [], 
    organisations = [], citations = [] } = entity;

  const view = entity.path;

  const communityFilters = useCommunityFilters('innovation-community');
  const marketMapFilters = useMarketMapFilters('innovation-community');

  const t = useLang();

  const { hasCatalysts, hasCommunity, hasHorizons, hasLibrary, hasTechnologies, 
    filteredTechnologies = [], technologyOrganisations = [] } = useInnovationViewDefinition(entity);

  const { showView } = useView<InnovationView>('innovation', 'details');

  const { resultsFor, filters } = useView(view);
  const { types = [], technologies = [] } = filters;


  const filteredMarketMap = filteredTechnologies.filter(technology => 
    linkedMatchesFilter(compact([technology]), technologies) 
    // linkedMatchesFilter(compact([technology?.organisations?.type]), types) 
  );

  const filteredPeople = people.filter(person => (
    linkedMatchesFilter(person.technologies, technologies)
  ));

  const filteredOrganisations = organisations.filter(organisation => (
    linkedMatchesFilter(compact([organisation?.type]), types) &&
    linkedMatchesFilter(organisation.technologies, technologies)
  ));

  const filteredStakeholders = [
    ...(resultsFor({ type: 'Organisation', data: filteredOrganisations, by: 'name' })), 
    ...(resultsFor({ type: 'Person', data: filteredPeople, by: 'name' })),
  ];
  
  return (
    <My.Container className={cls.name(className)}>
      {
        showView('copper-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('details') && <>
          <My.Section>
            <My.ContentTitle>{t('Objectives')}</My.ContentTitle>
            <My.Description entity={description as TContentNode} />
          </My.Section>
          <My.Section>
            <My.ContentTitle>{t('Example')}</My.ContentTitle>
            <My.Example entity={example as TContentNode} />
          </My.Section>
        
          <My.Section>
            <My.ContentTitle>{t('Horizons')}</My.ContentTitle>
            {
              hasHorizons && 
              <>
                <My.Description entity={horizonItro}/>
                <My.Horizontype items={horizons} />
              </>
            }
          </My.Section>

          <My.Section>
          <My.ContentTitle>{t('Catalysts')}</My.ContentTitle>
            {
              hasCatalysts &&
              catalysts.map(catalyst => <My.Catalyst key={catalyst.id} entity={catalyst} />)
            }
          </My.Section>
          </>
      }
      {
        showView('community') && 
        <>
          <My.Section>
            {/* <My.Description entity={description as TContentNode} /> */}
          </My.Section>

          <My.Section>
            <My.Filters 
              view={view}
              selects={communityFilters.selects}
              typeToggles={communityFilters.typeToggles}
            />
          </My.Section>

          <My.Section>
          {
            hasCommunity && <My.Stakeholders items={filteredStakeholders} />
          }
          </My.Section>
          </>
      }
      {
        showView('stakeholder-map') && 
        <>
        <My.Section>
          <My.Filters 
            results={technologyOrganisations}
            view={view}
            selects={marketMapFilters.selects}
          />
        </My.Section>

        <My.Section>
        {
          hasTechnologies &&
          filteredMarketMap.map(({ name, organisations }) => (
            <My.StakeHolderMapContainer>
              <My.TechnologyTitle>
                <My.ContentTitle>{name}</My.ContentTitle>
              </My.TechnologyTitle>
              <My.MarketMap items={organisations} />
            </My.StakeHolderMapContainer>
          ))
        }
        </My.Section>
          </>
      }
      {
        showView('library') && 
          <My.Section>
            {
              hasLibrary && <My.Citations items={citations} card={CitationCard}/>
            }
          </My.Section>
      }
    </My.Container>
  )
};

InnovationViewLayout.defaultProps = InnovationViewLayoutDefaultProps;
InnovationViewLayout.cls = cls;