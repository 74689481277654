import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Innovation } from '@shapeable/copper-connect-types';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { InnovationView } from '../../types';
import { compact, flatMap, sortBy } from 'lodash';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
const cls = classNames('innovation-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type InnovationTabsLayoutProps = Classable & HasChildren & { 
  entity?: Innovation;
};

export const InnovationTabsLayoutDefaultProps: Omit<InnovationTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const TabsStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,
};

export const InnovationTabsLayout: Shapeable.FC<InnovationTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  const { people = [], organisations = [], citations = [], color } = entity;

  const contentTabColors: ColorTokens.Any = {
    hover: {
      text: color?.value,
      fill: color?.value,
    },
    active: {
      text: color?.value,
      fill: color?.value,
      border: color?.value,
    }
  };

  const stakeholders = [...sortBy(organisations, 'name'), ...sortBy(people, 'name')];

  const hasTechnologies = entity.technologies.filter(technology => technology?.organisations?.length > 0);

  const hasCommunity = !!stakeholders.length;
  const hasMarketMap = !!hasTechnologies.length; 
  const hasLibrary = !!citations.length; // TODO hook this up to library checks

  const tabs: ContentTabSpec<InnovationView>[] = compact([
    {
      id: 'copper-ai',
      icon: <AiUpperCaseIconGlyph size={30} />
    },
    {
      id: 'details',
      label: 'Details',
    },
    hasCommunity && {
      id: 'community',
      label: 'Community',
    },
    hasMarketMap && {
      id: 'stakeholder-map',
      label: 'Stakeholder Map',
    },
    hasLibrary && {
      id: 'library',
      label: 'Library',
    },
  ]);

  const view = entity.path;
  const { activeView, setActiveView } = useView<InnovationView>(view, 'details');
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={contentTabColors} />
  )
};

InnovationTabsLayout.defaultProps = InnovationTabsLayoutDefaultProps;
InnovationTabsLayout.cls = cls;